<template>
  <div
    style="
      background: linear-gradient(0deg, #dfab79, #ffffff);
      width: 100%;
      align-items: center;
    "
    class="flexColumn"
  >
    <div
      style="
        background: linear-gradient(0deg, #dfab79, #ffffff);
        width: 100%;
        justify-content: center;
        display: flex;
      "
      id="main"
    >
      <div class="peerSupport">
        <div class="header flexRow">
          {{ getContent(1329) }}
        </div>
        <div class="supportOptions flexRow">
          <div class="flexColumn image1" @click="link('../Questions?state=1')">
            {{ getContent(1330) }}
            <p class="subheader">{{ getContent(1368) }}</p>
          </div>
          <div class="flexColumn image2" @click="link('../Questions?state=0')">
            {{ getContent(1331) }}
            <p class="subheader">{{ getContent(1369) }}</p>
          </div>
          <div class="flexColumn image3" @click="link('../Questions?state=2')">
            {{ getContent(1332) }}
            <p class="subheader">{{ getContent(1370) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        background: linear-gradient(180deg, #dfab79, #ffffff);
        width: 100%;
        justify-content: center;
      "
      class="flexRow"
    >
      <div class="info">
        <div class="title">
          {{ getContent(1333) }}
        </div>
        <carousel-3d
          :autoplay="true"
          :autoplay-timeout="30000"
          :autoplayHoverPause="true"
          :display="3"
          :width="400"
          :height="500"
          :inverse-scaling="400"
          :perspective="35"
          :space="800"
          :controls-visible="!myWidth"
          :controls-prev-html="'&#10092; '"
          :controls-next-html="'&#10093;'"
          :controls-width="30"
          :animationSpeed="1000"
          :border="0"
          style="padding: 50px 0 0 0; display: none"
        >
          <slide
            v-for="(info, i) in this.howDoesItWork"
            :index="i"
            v-bind:key="i"
            style="background: transparent"
          >
            <template slot-scope="{ index, isCurrent }">
              <div class="howDoesItWork">
                <div class="image">
                  <img :src="info.img" />
                </div>
                <div
                  :data-index="index"
                  :class="{
                    'text-current': isCurrent,
                    'text-side': !isCurrent,
                  }"
                >
                  {{ info.text }}
                </div>
              </div>
            </template>
          </slide>
        </carousel-3d>

        <div
          v-bind:class="{
            flexRow: !myWidth,
            'flexColumn alignCenter': myWidth,
          }"
        >
          <div
            class="howDoesItWork"
            v-for="info in this.howDoesItWork"
            v-bind:key="info"
          >
            <div class="image">
              <img :src="info.img" />
            </div>
            <div class="text-current">
              {{ getContent(info.text) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flexColumn"
      style="
        width: 100%;
        justify-content: center;
        background: linear-gradient(0deg, #dfab79, #ffffff);
        align-items: center;
      "
    >
      <div class="behindPS">
        <div class="image">
          <img
            src="https://media.kaiser.fyi/mindsupport/500/ScienceMSabstractdesign.png"
          />
        </div>
        <div class="description">
          <div class="header">
            <div class="top">{{ getContent(1334) }}</div>
            <div class="bottom">{{ getContent(1335) }}</div>
          </div>
          <ul v-if="!myWidth">
            <!-- Desktop Liste -->
            <li>
              {{ getContent(1336) }}
            </li>
            <li>
              {{ getContent(1337) }}
            </li>
            <li>
              {{ getContent(1338) }}
            </li>
            <li>
              {{ getContent(1340) }}
            </li>
            <li>
              {{ getContent(1341) }}
            </li>
            <li>
              {{ getContent(1342) }}
            </li>
          </ul>
        </div>
      </div>
      <ul v-if="myWidth" id="points">
        <!-- Mobile Liste außerhalb -->
        <li>
          {{ getContent(1336) }}
        </li>
        <li>
          {{ getContent(1337) }}
        </li>
        <li>
          {{ getContent(1338) }}
        </li>
        <li>
          {{ getContent(1340) }}
        </li>
        <li>
          {{ getContent(1341) }}
        </li>
        <li>
          {{ getContent(1342) }}
        </li>
      </ul>
      <a
        href="https://ggz.nl/ANAMH/1edition/Mind-Support-Systematic-Review-of-Online-Peer-Support-Platforms.pdf"
        target="_blank"
      >
        <div class="readMore">
          <img
            src="https://media.kaiser.fyi/mindsupport/250/Read_more_2.png"
          /></div
      ></a>
    </div>
  </div>
</template>

<script>
import { loadData } from "@/utils";
export default {
  data() {
    return {
      /* Important for Mobile Layouts in HTML */
      myWidth: screen.height / screen.width > 1,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      text: {},
      howDoesItWork: [
        {
          img: "https://media.kaiser.fyi/mindsupport/200/HowDoesItWork1.png",
          text: 1357,
        },
        {
          img: "https://media.kaiser.fyi/mindsupport/200/HowDoesItWork2.png",
          text: 1358,
        },
        {
          img: "https://media.kaiser.fyi/mindsupport/200/HowDoesItWork3.png",
          text: 1359,
        },
        {
          img: "https://media.kaiser.fyi/mindsupport/200/HowDoesItWork4.png",
          text: 1360,
        },
      ],
    };
  },
  methods: {
    calcMyWidth() {
      this.myWidth = screen.height / screen.width > 1;
    },
    getContent: function (key) {
      return this.text[key];
    },
    link: function (l) {
      this.$router.push(l);
    },
  },
  async created() {
    this.text = await loadData("peerSupport", this.myLanguage);
    window.addEventListener("resize", this.calcMyWidth);
    screen.orientation.addEventListener("change", this.calcMyWidth);
    screen.orientation.onchange = this.calcMyWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.calcMyWidth);
    screen.removeEventListener("change", this.calcMyWidth);
  },
};
</script>

<style scoped>
.peerSupport {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.peerSupport .header {
  font-family: var(--h1);
  color: #ffffff;
  background-image: url("https://media.kaiser.fyi/mindsupport/1280/PeerSupport.png");
  margin-bottom: 2%;
  height: 15vw;
  background-size: 100% 100%;
  font-size: 10vw;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  max-width: 1280px;
  width: 100%;
}
.peerSupport .supportOptions {
  font-family: var(--h2);
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 1280px;
  width: 100%;
}
.peerSupport h2 {
  line-height: 6vh;
}
.peerSupport .image1,
.peerSupport .image2,
.peerSupport .image3 {
  --width: 32vw;
  background-size: 100% 100%;
  width: var(--width);
  height: calc(var(--width) * (1971 / 1076));
  display: flex;
  justify-content: center;
  padding-top: 10%;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 5vw;
  line-height: 6vw;
  transition: 0.2s ease-in-out;
  color: white;
}
.peerSupport .image1 {
  background-image: url("https://media.kaiser.fyi/mindsupport/600/ReceiveSupport.png");
  /*padding-top: 13%;*/
  /* Seemed to disrupt the layout? */
}
.peerSupport .image2 {
  background-image: url("https://media.kaiser.fyi/mindsupport/600/SupportOthers.png");
}
.peerSupport .image3 {
  background-image: url("https://media.kaiser.fyi/mindsupport/600/MutualSupport.png");
}
.peerSupport .image1:hover,
.peerSupport .image2:hover,
.peerSupport .image3:hover {
  opacity: 0.8;
  transform: scale(0.98);
}
.peerSupport .flexColumn {
  justify-content: space-between;
  padding-top: 10%;
}
.peerSupport .subheader {
  font-size: 20px;
  font-family: var(--text);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: none;
  line-height: 1.7rem;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 100%;
}
a h2 {
  text-decoration: none;
  color: black !important;
}
.info .title {
  font-family: var(--h1);
  font-size: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0 60px 0;
  color: #4f83ab;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 2px 2px #4f83ab52;
}
.info .howDoesItWork .image {
  display: flex;
  justify-content: center;
}
.info .howDoesItWork .image img {
  width: 200px;
  max-width: 40vw;
  height: auto;
  margin: auto;
  border-radius: 50%;
}
.info .howDoesItWork .text-current {
  text-align: justify;
  transition: 1s;
  display: flex;
  padding: 10%;
}

.info .howDoesItWork .text-side {
  text-align: center;
  opacity: 0;
  transition: 1s;
}
.info {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
}
.info .howDoesItWork {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;
}
.behindPS {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  justify-content: center;
  margin-top: 7em;
  margin-bottom: 7em;
}
.behindPS .image {
  display: flex;
  justify-content: center;
  max-width: 30%;
}
.behindPS .image img {
  height: 384px;
  width: 384px;
  margin: 50px 50px 0 50px;
}
.behindPS .description {
  font-size: 1em;
  margin: 50px 50px 0 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.behindPS .description li,
.flexColumn li {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: -20px;
}
.behindPS .description .header {
  font-size: 7em;
  line-height: 1em;
}
.behindPS .description .header .top {
  color: var(--c3); /* darker: color: #000761;*/
  font-family: var(--h1);
  font-weight: bold;
  text-transform: uppercase;
}
.behindPS .description .header .bottom {
  font-size: 0.75em;
  color: var(--c4);
  font-family: var(--h2);
}
.readMore {
  width: 10vw;
  margin: 1em auto;
  padding: 15px;
  transition: all 0.4s ease 0s;
}
.readMore:hover {
  opacity: 0.7;
  transform: scale(1.1);
}
.alignCenter {
  align-items: center;
}

@media only screen and (min-width: 1280px) {
  .peerSupport .header {
    font-size: 127.8px;
    --width: 1280px;
    width: var(--width);
    height: calc(var(--width) * (333 / 2109));
  }
  .peerSupport .image1,
  .peerSupport .image2,
  .peerSupport .image3 {
    --width: calc(1227.81px / 3);
    font-size: 68.87px;
    line-height: 68.87px;
  }
}

@media only screen and (max-width: 1280px) {
  .peerSupport {
    width: 95%;
  }
  .image1 {
    margin-right: 1.25vw;
  }
  .image2 {
    margin-right: 1.25vw;
    margin-left: 1.25vw;
  }
  .image3 {
    margin-left: 1.25vw;
  }
  .behindPS {
    flex-direction: column;
    justify-content: center;
    flex-direction: row;
    width: 95%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .behindPS .image {
    width: 100%;
    max-width: 33%;
    height: 100%;
    margin: 0 auto;
    margin-top: 1vh;
    margin-right: 1vw;
  }
  .behindPS .image img {
    width: 100%;
    height: auto;
  }
  .behindPS .description {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .behindPS .description .header {
    font-size: 3em;
    line-height: 1em;
    text-align: center;
    margin-top: 2em;
    overflow-x: hidden;
  }
  #points {
    width: 90%;
  }
  li {
    text-align: left;
  }
  .readMore {
    width: 20vw;
  }
}

@media only screen and (max-width: 640px) {
  /* For mobile phones: */
  .peerSupport .image1,
  .peerSupport .image2,
  .peerSupport .image3 {
    line-height: 1;
    padding-top: 5%;
  }
  .peerSupport .subheader {
    font-size: 0.6em;
    line-height: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  .info .title {
    font-size: 3.5em;
    padding-top: 1.5em;
  }
  .info .howDoesItWork .text-current {
    text-align: center;
  }
  .info .howDoesItWork {
    width: 90%;
  }
  .behindPS .image {
    max-width: 33%;
    min-width: 150px;
  }
  .readMore {
    width: 50vw;
  }
}
</style>
